<template>
  <div class="about">
    <img src="@/assets/work_from_home.png" alt="services image" class="about-image" />
    <div class="about-text">
      <h2>{{ $t('originStoryTitle') }}</h2>
      <p>{{ $t('originStoryParagraphOne') }}</p>
      <p>{{ $t('originStoryParagraphTwo') }}</p>
      <p>{{ $t('originStoryParagraphThree') }}</p>
      <p>{{ $t('originStoryParagraphFour') }}</p>
      <p>{{ $t('originStoryParagraphFive') }}</p>
      <p>{{ $t('originStoryParagraphSix') }}</p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'about-us'
}
</script>

<style scoped>
.about {
  display: flex;
  align-items: center;
  padding: 60px 20px;
  background-color: #f9f9f9;
  text-align: left;
}

.about-image {
  width: 40%;
  margin-right: 40px;
  border-radius: 10px;
}

.about-text {
  width: 60%;
}

.about h2 {
  color: #333;
}

.about p {
  color: #666;
  line-height: 1.6;
}

@media (max-width: 768px) {
  .about {
    flex-direction: column;
    padding: 20px;
  }

  .about-image {
    width: 100%;
    margin-right: 0;
    margin-bottom: 20px;
  }

  .about-text {
    width: 100%;
  }
}
</style>
