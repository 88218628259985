<template>
  <section id="home-section">
    <div class="home">
      <section id="hero">
        <Hero />
      </section>
      <section id="services" class="services">
        <Services />
      </section>
      <section id="about">
        <AboutUs />
      </section>
      <section id="why-choose-us" class="why-choose-us">
        <WhyChooseUs />
      </section>
      <section id="footer" class="footer">
        <ContactFooter />
      </section>
    </div>
  </section>
</template>

<script>
import AboutUs from './AboutUs.vue'
import Hero from './Hero.vue'
import Services from './Services.vue'
import WhyChooseUs from './WhyChooseUs.vue'
import ContactFooter from './ContactFooter.vue'

export default {
  name: 'home-page',
  components: {
    AboutUs,
    Hero,
    Services,
    WhyChooseUs,
    ContactFooter
  },
  mounted () {
    const hash = this.$route.hash
    if (hash) {
      const element = document.querySelector(hash)
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' })
      }
    }
  },
  watch: {
    '$route.hash' (newHash) {
      if (newHash) {
        const element = document.querySelector(newHash)
        if (element) {
          element.scrollIntoView({ behavior: 'smooth' })
        }
      }
    }
  }
}
</script>

<style scoped>
.home {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

section {
  padding: 0;
  margin: 0;
}

.hero  {
  padding-top: 100px;
}

.services {
  padding: 40px 20px;;
}

.about {
  padding: 60px 20px;
}

</style>
