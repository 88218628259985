<template>
  <div class="modal-overlay" v-if="visible" @click.self="close">
    <div class="modal-content">
      <button class="close-button" @click="close">&times;</button>
      <h2>{{ $t(service.titleKey) }}</h2>
      <p>{{ $t(service.descriptionKey) }}</p>
      <p>{{ $t(service.secondDescriptionKey) }}</p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'modal-component',
  props: {
    visible: {
      type: Boolean,
      required: true
    },
    service: {
      type: Object,
      required: true
    }
  },
  methods: {
    close () {
      this.$emit('close')
    }
  }
}
</script>

<style scoped>
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-content {
  background: white;
  padding: 20px;
  border-radius: 10px;
  max-width: 500px;
  width: 100%;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.close-button {
  background: none;
  border: none;
  font-size: 1.5em;
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
}
</style>
