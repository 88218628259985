const { createRouter, createWebHistory } = require('vue-router')
const Home = require('../components/Home.vue').default
const Contact = require('@/components/Contact.vue').default

const routes = [
  { path: '/', component: Home },
  { path: '/contact', component: Contact }
]

const router = createRouter({
  history: createWebHistory(),
  routes
})

module.exports = router
