<template>
  <div class="contact-content">
    <div class="title">
      <h1>{{ $t('contactUsTitleOne') }}<br>{{ $t('contactUsTitleTwo') }}</h1>
    </div>
    <div class="contact-container">
      <div class="contact-item">
        <i class="fas fa-envelope"></i>
        <span>office@arkayl.org</span>
      </div>
    </div>
    <div class="contact">
      <h2>{{ $t('contactUsFormTitle') }}</h2>
      <form @submit.prevent="submitForm">
        <div class="form-group">
          <label for="name">{{ $t('contactUsFormName') }}:</label>
          <input type="text" id="name" v-model="form.name" required>
        </div>
        <div class="form-group">
          <label for="email">Email:</label>
          <input type="email" id="email" v-model="form.email" required>
        </div>
        <div class="form-group">
          <label for="message">{{ $t('contactUsFormMessage') }}:</label>
          <textarea id="message" v-model="form.message" required></textarea>
        </div>
        <button type="submit">{{ $t('contactUsFormSubmit') }}</button>
      </form>
    </div>
  </div>
  <ContactFooter />
</template>

<script>
import ContactFooter from './ContactFooter.vue'

export default {
  name: 'contact-page',
  components: {
    ContactFooter
  },
  data () {
    return {
      form: {
        name: '',
        email: '',
        message: ''
      }
    }
  },
  methods: {
    async submitForm () {
      if (!this.isValidEmail(this.form.email)) {
        alert('Please enter a valid email address.')
        return
      }

      try {
        const response = await fetch('http://192.168.1.16:8080/v1/send-email', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(this.form),
          mode: 'no-cors'
        })

        if (response.ok) {
          alert('Email sent successfully')
          this.form.name = ''
          this.form.email = ''
          this.form.message = ''
        } else {
          alert('Error sending email', response.json)
        }
      } catch (error) {
        console.error('Error:', error)
        alert('Error sending email')
      }
    },
    isValidEmail (email) {
      return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)
    }
  }
}
</script>

<style scoped>
.contact-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding: 20px;
  box-sizing: border-box;
}

.contact-item {
  display: flex;
  align-items: center;
  margin: 20px 0;
  padding: 20px;
  background-color: white;
  border-radius: 50px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: box-shadow 0.3s ease;
}

.contact-item:hover {
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

.contact-item i {
  font-size: 1.5em;
  margin-right: 10px;
  color: #007bff;
}

.contact {
  flex-grow: 1;
  max-width: 600px;
  width: 100%;
  margin: 20px;
  padding: 20px;
  background-color: #ffffff;
  border-radius: 10px;
  box-shadow: 4px 4px 8px 4px rgba(0, 0, 0, 0.1);
  transition: box-shadow 0.3s ease;
  box-sizing: border-box;
}

.contact:hover {
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

.title {
  text-align: center;
  margin-top: 30px;
  color: #333;
  font-size: 2em;
}

h2 {
  text-align: center;
  margin-bottom: 30px;
  color: #333;
  font-size: 2em;
}

.form-group {
  margin-bottom: 20px;
}

label {
  display: block;
  margin-bottom: 8px;
  font-weight: bold;
  color: #555;
}

input,
textarea {
  width: 100%;
  padding: 12px;
  border: 1px solid #ccc;
  border-radius: 5px;
  box-sizing: border-box;
  transition: border-color 0.3s ease, box-shadow 0.3s ease;
}

input:focus,
textarea:focus {
  border-color: #007bff;
  box-shadow: 0 0 8px rgba(0, 123, 255, 0.2);
  outline: none;
}

textarea {
  resize: vertical;
  min-height: 150px;
}

button {
  display: block;
  width: 100%;
  padding: 15px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 1.2em;
  transition: background-color 0.3s ease, transform 0.3s ease;
}

button:hover {
  background-color: #0056b3;
  transform: translateY(-2px);
}

button:active {
  background-color: #004494;
  transform: translateY(0);
}

@media (max-width: 768px) {
  .contact {
    margin: 10px 0;
    padding: 15px;
  }

  .title {
    font-size: 15px;
  }

  h2 {
    font-size: 1.5em;
  }

  button {
    font-size: 1em;
    padding: 12px;
  }
}
</style>
