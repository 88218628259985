import { createI18n } from 'vue-i18n'

import en from './locales/en.json'
import ro from './locales/ro.json'
import ru from './locales/ru.json'

const messages = {
  en,
  ro,
  ru
}

const i18n = createI18n({
  locale: 'en',
  fallbackLocale: 'en',
  messages
})

export default i18n
