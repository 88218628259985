<template>
  <div class="contact-footer">
    <img src="@/assets/arkayl_logo.png" alt="footer image" class="footer-image" />
    <div class="contact-info">
      <p>{{ $t('contactFooterCopyRight') }}</p>
      <p>Office: R.Moldova, Floresti, Lunga</p>
      <p>Office: Romania, Bihor, Oradea</p>
      <p>Email: office@arkayl.org</p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'contact-footer'
}
</script>

<style scoped>
.contact-footer {
  background: linear-gradient(45deg, #00ffcc, #1a1a1a);
  background-size: cover;
  padding: 40px 20px;
  color: white;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 20vh;
}

.contact-info {
  flex: 1;
  text-align: right;
}

.contact-footer p {
  font-size: 1.2em;
  margin: 5px 0;
}

.footer-image {
  width: 200px;
  height: auto;
  margin-left: 20px;
}

@media (max-width: 768px) {
  .contact-footer {
    flex-direction: column;
    text-align: center;
    height: auto;
    padding: 20px 10px;
  }

  .footer-image {
    width: 150px;
    margin: 0 auto 20px;
  }

  .contact-info {
    text-align: center;
  }

  .contact-footer p {
    font-size: 1em;
  }
}
</style>
