<template>
  <div class="why-choose-us">
    <h2>{{ $t('whyChooseUsTitle') }}</h2>
    <p>{{ $t('whyChooseUsSubtitle') }}</p>
    <ul class="reasons-list">
      <li class="reason-item">
        <h3>{{ $t('reasonTitle_1') }}</h3>
        <p>{{ $t('reasonDescription_1') }}</p>
      </li>
      <li class="reason-item">
        <h3>{{ $t('reasonTitle_2') }}</h3>
        <p>{{ $t('reasonDescription_2') }}</p>
      </li>
      <li class="reason-item">
        <h3>{{ $t('reasonTitle_3') }}</h3>
        <p>{{ $t('reasonDescription_3') }}</p>
      </li>
      <li class="reason-item">
        <h3>{{ $t('reasonTitle_4') }}</h3>
        <p>{{ $t('reasonDescription_4') }}</p>
      </li>
      <li class="reason-item">
        <h3>{{ $t('reasonTitle_5') }}</h3>
        <p>{{ $t('reasonDescription_5') }}</p>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: 'WhyChooseUs'
}
</script>

<style scoped>
.why-choose-us {
  background-color: #f9f9f9;
  padding: 40px 20px;
  text-align: center;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.why-choose-us h2 {
  font-size: 2.5em;
  margin-bottom: 20px;
  color: #333;
}

.why-choose-us p {
  font-size: 1.2em;
  margin-bottom: 30px;
  color: #666;
}

.reasons-list {
  list-style: none;
  padding: 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
}

.reason-item {
  background-color: white;
  padding: 20px;
  border: 1px solid #ddd;
  border-radius: 10px;
  width: 250px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;
}

.reason-item:hover {
  transform: translateY(-10px);
}

.reason-item h3 {
  font-size: 1.5em;
  margin-bottom: 10px;
  color: #007bff;
}

.reason-item p {
  font-size: 1em;
  color: #666;
}

@media (max-width: 768px) {
  .why-choose-us {
    padding: 20px 10px;
  }

  .why-choose-us h2 {
    font-size: 2em;
  }

  .why-choose-us p {
    font-size: 1em;
  }

  .reason-item {
    width: 100%;
    margin-bottom: 20px;
  }
}
</style>
