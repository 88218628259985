<template>
  <div class="services">
    <h1>{{ $t('servicesTitle') }}</h1>
    <p>{{ $t('servicesSubtitle') }}</p>
    <div class="service-content">
      <div class="service-list-wrapper">
        <div class="service-list">
          <div class="service-item" v-for="service in services" :key="service.id" @click="openModal(service)">
            <h2>{{ $t(service.titleKey) }}</h2>
            <p>{{ $t(service.descriptionKey) }}</p>
          </div>
        </div>
      </div>
      <img src="@/assets/laptop.png" alt="Services Image" class="service-image" />
    </div>
    <Modal :visible="isModalVisible" :service="selectedService" @close="closeModal" />
  </div>
</template>

<script>
import Modal from './Modal.vue'

export default {
  name: 'services-page',
  components: {
    Modal
  },
  data () {
    return {
      services: [
        {
          id: 1,
          titleKey: 'serviceListItemTitle_1',
          descriptionKey: 'serviceListItemDescription_1',
          secondDescriptionKey: 'serviceListItemSecondDescription_1'
        },
        {
          id: 2,
          titleKey: 'serviceListItemTitle_2',
          descriptionKey: 'serviceListItemDescription_2',
          secondDescriptionKey: 'serviceListItemSecondDescription_2'
        },
        {
          id: 3,
          titleKey: 'serviceListItemTitle_3',
          descriptionKey: 'serviceListItemDescription_3',
          secondDescriptionKey: 'serviceListItemSecondDescription_3'
        },
        {
          id: 4,
          titleKey: 'serviceListItemTitle_4',
          descriptionKey: 'serviceListItemDescription_4',
          secondDescriptionKey: 'serviceListItemSecondDescription_4'
        },
        {
          id: 5,
          titleKey: 'serviceListItemTitle_5',
          descriptionKey: 'serviceListItemDescription_5',
          secondDescriptionKey: 'serviceListItemSecondDescription_5'
        },
        {
          id: 6,
          titleKey: 'serviceListItemTitle_6',
          descriptionKey: 'serviceListItemDescription_6',
          secondDescriptionKey: 'serviceListItemSecondDescription_6'
        }
      ],
      isModalVisible: false,
      selectedService: {}
    }
  },
  methods: {
    openModal (service) {
      console.log(service)
      this.selectedService = service
      this.isModalVisible = true
    },
    closeModal () {
      this.isModalVisible = false
      this.selectedService = {}
    }
  }
}
</script>

<style scoped>
.services {
  max-width: 1600px;
  margin: 0 auto;
  padding: 20px;
  text-align: center;
}

h1 {
  color: #333;
}

p {
  color: #666;
  line-height: 1.6;
}

.service-content {
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  gap: 20px;
  flex-wrap: wrap;
}

.service-list-wrapper {
  flex: 1;
}

.service-list {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 20px;
}

.service-item {
  background-color: white;
  padding: 20px;
  border: 1px solid #ddd;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;
  cursor: pointer;
}

.service-item:hover {
  transform: translateY(-10px);
}

.service-item h2 {
  margin-bottom: 10px;
  color: #007bff;
}

.service-item p {
  color: #666;
}

.service-image {
  max-width: 600px;
  width: 100%;
  height: auto;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  object-fit: cover;
  align-self: stretch;
}

@media (max-width: 768px) {
  .service-content {
    flex-direction: column;
    align-items: center;
  }

  .service-image {
    max-width: 100%;
    margin-top: 20px;
  }
}

@media (max-width: 400px) {
  .services {
    padding: 10px;
  }

  h1 {
    font-size: 1.5em;
  }

  p {
    font-size: 1em;
  }

  .service-item {
    padding: 10px;
  }

  .service-item h2 {
    font-size: 1.2em;
  }

  .service-item p {
    font-size: 0.9em;
  }

  .service-image {
    max-width: 100%;
    margin-top: 10px;
  }
}
</style>
