<template>
  <div class="hero">
    <div class="hero-content">
      <h1>Arkayl Solutions</h1>
      <p>{{ $t('heroContent') }}</p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'hero-section'
}
</script>

<style scoped>
.hero {
  background: linear-gradient(45deg, #1a1a1a, #00ffcc);
  background-size: cover;
  padding: 0 20px;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 30vh;
  text-align: center;
}

.hero-content {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.hero h1 {
  font-size: 3em;
}

.hero p {
  font-size: 1.5em;
}

@media (max-width: 768px) {
  .hero {
    padding: 0 10px;
    height: auto;
  }

  .hero-content {
    flex-direction: column;
  }

  .hero h1 {
    font-size: 2em;
    margin-right: 0;
    margin-bottom: 10px;
  }

  .hero p {
    font-size: 1.2em;
    margin-right: 0;
    margin-bottom: 10px;
  }
}
</style>
