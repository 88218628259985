<template>
  <nav class="main-menu">
    <div class="logo">
      <img src="@/assets/arkayl_logo_clear.png" alt="Logo">
    </div>
    <ul class="menu-options">
      <li><router-link to="/#hero">{{ $t('menuHome') }}</router-link></li>
      <li><router-link to="/#services">{{ $t('menuServices') }}</router-link></li>
      <li><router-link to="/#about">{{ $t('menuAbout') }}</router-link></li>
      <li><router-link to="/contact">{{ $t('menuContact') }}</router-link></li>
    </ul>
    <div class="language-section">
      <button @click="changeLanguage('en')"><span class="fi fi-gb"></span> English</button>
      <button @click="changeLanguage('ro')"><span class="fi fi-ro"></span> Romanian</button>
      <button @click="changeLanguage('ru')"><span class="fi fi-ru"></span> Russian</button>
    </div>
  </nav>
</template>

<script>
export default {
  name: 'MainMenu',
  methods: {
    changeLanguage (locale) {
      this.$i18n.locale = locale
    }
  }
}
</script>

<style scoped>
.main-menu {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
  background-color: white;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1000;
}

.logo img {
  height: 80px;
}

.menu-options {
  list-style: none;
  display: flex;
  gap: 40px;
  margin: 0;
  padding: 0;
}

.menu-options li {
  display: inline;
}

.menu-options a {
  color: black;
  text-decoration: none;
  font-weight: bold;
}

.menu-options a:hover {
  text-decoration: underline;
}

.language-section {
  display: flex;
  gap: 10px;
}

.language-section button {
  cursor: pointer;
  display: flex;
  align-items: center;
  padding: 5px;
  border: 1px solid #ccc;
  border-radius: 5px;
  background: none;
  border: none;
}

.language-section button:hover {
  background-color: #f0f0f0;
}

.language-section .fi {
  margin-right: 5px;
}

@media (max-width: 768px) {
  .main-menu {
    flex-direction: row;
    align-items: center;
    padding: 2px;
  }

  .logo img {
    height: 60px;
    margin-bottom: 10px;
  }

  .menu-options {
    flex-direction: row;
    width: 100%;
    gap: 5px;
  }

  .menu-options li {
    width: auto;
  }

  .menu-options a {
    display: row;
    width: 100%;
    padding: 5px 10px;
    border-radius: 5px;
  }

  .menu-options a:hover {
    background-color: #e0e0e0;
  }

  .language-section {
    display: none;
  }
}
</style>
